import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const ProjectCard = ({
  image,
  title,
  subtitle,
  slug,
}) => {

  return (
    <Link to={slug}>
      <div className="group flex flex-col justify-end relative overflow-hidden">
        {image?.gatsbyImageData && (
          <div className="relative">
            <GatsbyImage
              className="group-hover:scale-110 group-hover:opacity-60 ease-in duration-200 aspect-w-1 aspect-h-1"
              image={image?.gatsbyImageData}
              alt={title}
            />
          </div>
        )}

        <div className="flex flex-col justify-center md:opacity-0 absolute top-0 md:group-hover:opacity-100 z-30 h-full w-full p-4 md:ease-in md:duration-100 group-hover:bg-white group-hover:bg-opacity-60 bg-white md:bg-none bg-opacity-60 md:bg-opacity-0">
          <h3 className="block text-lg md:text-lg text-center tracking-wider text-gold">
            {title}
          </h3>
          {subtitle && (
            <h4 className="text-xs md:text-xs text-center tracking-wide text-gold">
              {subtitle}
            </h4>
          )}
        </div>
      </div>
    </Link>
  )
}

ProjectCard.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default ProjectCard
